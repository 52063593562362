<template>
  <div class="notice">
        <top-bar :title="'交办任务提醒'" :left="true"></top-bar>
        <van-tabs v-model="active" class="tab" title-active-color="#027eff" color="#027eff">
          <van-tab active = '0' title="待处理" title-style="border-right: 1px solid #eee;"></van-tab>
          <van-tab active = '1' title="已完成"></van-tab>
        </van-tabs>
    <div class="filterBar">
      <span @click="showPicker = true">任务类型 <van-icon name="arrow-down" /></span>
      <span @click="showTimer = true" v-show="active == '1'">完成时间 <van-icon name="arrow-down" /></span>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="showTimer" round position="bottom" ref="datePicker">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择时间"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="dateCancel"
          @confirm="dateConfirm"
      />
    </van-popup>
    <div class="cont" v-show="active == '0'">
      <van-list offset="10">
        <van-cell @click="goInfo">
            <van-cell-group >
              <van-cell class="title" center>
                <span>子火棋牌室</span><span class="right">(检查类)</span>
                <span>2021-06-30</span>
              </van-cell>
              <van-cell class="itemCont" is-link center icon-prefix="widthImg">
                  <span class="custom-title contTitle">针对辖境内的沿街商铺进行消防检查安全</span>
              </van-cell>
            </van-cell-group>
        </van-cell>
        <van-cell @click="goInfo">
            <van-cell-group >
              <van-cell class="title" center>
                <span>子火棋牌室</span><span class="right">(检查类)</span>
                <span>2021-06-30</span>
              </van-cell>
              <van-cell class="itemCont" is-link center icon-prefix="widthImg">
                <span class="custom-title contTitle">针对辖境内的沿街商铺进行消防检查安全</span>
              </van-cell>
            </van-cell-group>
        </van-cell>
      </van-list>
<!--      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>-->
    </div>
    <div class="cont" v-show="active == '1'">
      <van-list offset="10">
        <van-cell @click="goInfo">
          <van-cell-group >
            <van-cell class="title" center>
              <span>占道经营、出店经营</span><span class="right">(城管类)</span>
              <span>2021-06-01</span>
            </van-cell>
            <van-cell class="itemCont" is-link center icon-prefix="widthImg">
              <span class="custom-title contTitle">滨盛路与三岔路交界处有大量小商贩占道经营，导致车辆出行遇到重大问题</span>
            </van-cell>
          </van-cell-group>
        </van-cell>
        <van-cell>
          <van-cell-group >
            <van-cell class="title" center>
              <span>纠纷打架类警情</span><span class="right">(公安类)</span>
              <span>2021-6-30</span>
            </van-cell>
            <van-cell class="itemCont" is-link center icon-prefix="widthImg">
              <span class="custom-title contTitle">古荡新村2幢1单元3楼的两位住户，成天因为漏水问题发起纠纷，请迅速去进行调解</span>
            </van-cell>
          </van-cell-group>
        </van-cell>
      </van-list>
<!--      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>-->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import { mapMutations } from 'vuex'
export default {
    components :{
        topBar
    },
     data() {
        return {
          active: '',
          loading: false,
          finished: false,
          showPicker: false,
          showTimer: false,
          limit: 10,
          page: 0,
          dataList: [],
          columns: ['检查类', '城管类', '公安类'],
          minDate: new Date(2020, 0, 1),
          maxDate: new Date(2025, 10, 1),
          currentDate: new Date(2021, 0, 17),
        }
     },
    methods: {
      onConfirm(value) {
        this.value = value
        this.showPicker = false
      },
      goInfo() {
        this.$router.push('/mission-info')
      },
      dateCancel() {
        console.log('取消')
        this.$refs.datePicker.close()
      },
      dateConfirm(e) {
        this.$refs.datePicker.close()
      }
    }
}
</script>

<style lang="scss" scoped>
.notice {
    padding-top: 100px;
    .tab {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }
  .cont {
    padding: 20px;
    background-color: #f5f5f5;
    .van-cell {
      position: relative;
      padding: 15px 20px;
      margin-bottom: 30px;
      border-radius: 10px;
      .title {
        margin: 0;
        font-size: 35px;
        span:nth-child(2) {
          font-size: 30px;
        }
        span:nth-child(3) {
          position: absolute;
          top: 0;
          right: 5px;
          //margin-left: 150px;
        }
      }
    }
    [class*=van-hairline]::after {
      border: none;
    }
    .van-cell::after {
      border-bottom: none;
    }
    ul {
      width: 100%;
      height: 100%;
      li {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }
  .contTitle {
    font-size: 30px;
    width: 420px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a2a2a2;
    span {
      color: #3D7EFE;
      font-size: 30px;
    }
  }
  .widthImg {
    height: 100%;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .filterBar {
    height: 80px;
    text-align: center;
    line-height: 80px;
    font-size: 28px;
    span:nth-child(2) {
      margin-left: 200px;
    }
  }

}
</style>
